import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { getUser } from "./api/register"
import { updateCurrentUser } from "./redux/reducers/authentication"
import { getUserData, isNullOrUndefined } from "./utility/Utils"
import NotAllowed from "./views/pages/misc/NotAllowed"

const AppPermissions = props => {
  const getTranslation = useTranslation().t
  const [isUserEnabled, setIsUserEnabled] = useState(true)

  const dispatch = useDispatch()
  const token = getUserData()

  useEffect(() => {
    if (token) {
      getUser()
        .then(({data}) => {
          if (data) {
            dispatch(updateCurrentUser(data))
            if (!isNullOrUndefined(data.enable)) {
              setIsUserEnabled(data.enable)
            }
          }
        })
        .catch(({response}) => {
          if (response?.data?.detail) {
            showError(getTranslation(response.data.detail) ?? response.data.detail)
          }
          localStorage.removeItem("edrak-token")
          window.location.reload()
        })
    }
  }, [token])

  return isUserEnabled ? props.children : <NotAllowed />
}

export default AppPermissions
